import React, { useEffect, useState } from "react";
import { Avatar, FormControlLabel, TextField, Checkbox } from "@mui/material";
import CameraSVG from "../../images/camera.svg";
import InputMask from "react-input-mask";
import { useDispatch } from "react-redux";
import { addToast } from "../../redux/toastSlice";
import AddressFields from "./AddressFields";
import { diffImageSrc } from "../../utils";
import { resizeFile } from '../../utils/imageResizer';
import { CHECKBOX_DISPLAY_IN_EMAIL } from "../../constants";

const addressFields = {
  line: "",
  zipcode: "",
  city: "",
  state: {},
  country: {},
};

const BusinessDetail = ({ id, userData, setBusinessDetailChanges }) => {
  const [businessDetail, setBusinessDetail] = useState({
    logo: null,
    name: "",
    email: "",
    phone_number: "",
    addresses: [],
    display_email: false,
    display_contact: false,
    display_city: false,
  });

  const dispatch = useDispatch();

  const {
    addresses,
    logo,
    name,
    email,
    phone_number,
  } = businessDetail;

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    const store = userData?.stores?.[0] || {};

    setBusinessDetail((prevDetail) => {
      if (value !== store[name]) {
        setBusinessDetailChanges((prevChanges) => ({
          ...prevChanges,
          [name]: value,
        }));
      } else {
        setBusinessDetailChanges((prevChanges) => {
          const updatedChanges = { ...prevChanges };
          delete updatedChanges[name];
          return updatedChanges;
        });
      }
      return { ...prevDetail, [name]: value };
    });
  };

  const handleFileChange = async (event) => {
    const file = await resizeFile(event.target.files[0]);
    if (file && file.type.startsWith("image/")) {
      setBusinessDetail((prevDetail) => {
        if (file !== userData?.stores?.[0]?.logo_url) {
          setBusinessDetailChanges((prevChanges) => ({
            ...prevChanges,
            logo: file,
          }));
        } else {
          setBusinessDetailChanges((prevChanges) => {
            const updatedChanges = { ...prevChanges };
            delete updatedChanges.logo;
            return updatedChanges;
          });
        }
        return { ...prevDetail, logo: file };
      });
    } else {
      dispatch(addToast("Please select an image file."));
    }
  };

  const handleDisplayInEmailCheckbox = (event) => {
    const { checked, name } = event.target;
    setBusinessDetail((prev) => ({
      ...prev,
      [name]: checked,
    }));
    setBusinessDetailChanges((prevChanges) => ({
      ...prevChanges,
      [name]: checked,
    }));
  };

  useEffect(() => {
    if (userData) {
      const { organization } = userData;

      if (organization) {
        const { logo_url, name, email, phone_number, addresses, display_email, display_contact, display_city } = organization;
        setBusinessDetail({
          logo: logo_url,
          name: name,
          email: email,
          phone_number: phone_number,
          addresses: addresses || [],
          display_email,
          display_contact,
          display_city,
        });
      }
    }
  }, [userData]);

  return (
    <div className="flex flex-col gap-6 p-8 bg-white rounded-lg w-full">
      <div className="text-stone-800 text-lg font-semibold font-['Montserrat'] leading-[25.20px]">
        Business details
      </div>
      <div className="flex flex-col lg:flex-row gap-16 justify-between">
        <div className="min-w-[18%]">
          <div className="flex flex-col gap-3 items-center">
            <div className="text-stone-800 text-base font-normal font-['Questrial'] leading-snug hidden md:block">
              Company logo
            </div>
            <Avatar
              alt="profile"
              src={logo ? diffImageSrc(logo) : CameraSVG}
              sx={{ width: 95, height: 95 }}
              slotProps={{ img: { style: { objectFit: "contain" } } }}
            />
            <a
              className="text-[#4c8c4a] underline cursor-pointer"
              onClick={() =>
                document.getElementById("businessLogoInput").click()
              }
            >
              Upload
            </a>
            <input
              id="businessLogoInput"
              type="file"
              style={{ display: "none" }}
              accept="image/*"
              onChange={handleFileChange}
            />
          </div>
        </div>

        <div className="flex flex-col gap-5 w-full">
          <div className="flex flex-col gap-2">
            <div className="w-40 h-[22px] text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
              Company name
            </div>
            <TextField
              id="companyName"
              placeholder="Please enter"
              variant="outlined"
              name="name"
              value={name}
              onChange={handleInputChange}
            />
          </div>

          <div className="flex flex-col sm:flex-row gap-4">
            <div className="flex flex-col gap-2 w-full">
              <div className="w-40 h-[22px] text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
                Contact email
              </div>
              <TextField
                id="contactEmail"
                placeholder="Please enter"
                variant="outlined"
                name="email"
                value={email}
                onChange={handleInputChange}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="display_email"
                    inputProps={{ "aria-label": "controlled" }}
                    checked={businessDetail?.display_email}
                    onChange={handleDisplayInEmailCheckbox}
                  />
                }
                label={CHECKBOX_DISPLAY_IN_EMAIL}
              />
            </div>

            <div className="flex flex-col gap-2 w-full">
              <div className="w-40 h-[22px] text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
                Phone number
              </div>
              <InputMask
                mask="(999) 999-9999"
                value={phone_number}
                onChange={handleInputChange}
              >
                {() => (
                  <TextField
                    id="phone_number"
                    name="phone_number"
                    type="tel"
                    placeholder="(123) 456-7890"
                    className="flex-1"
                    variant="outlined"
                  />
                )}
              </InputMask>
              <FormControlLabel
                control={
                  <Checkbox
                    name="display_contact"
                    inputProps={{ "aria-label": "controlled" }}
                    checked={businessDetail?.display_contact}
                    onChange={handleDisplayInEmailCheckbox}
                  />
                }
                label={CHECKBOX_DISPLAY_IN_EMAIL}
              />
            </div>
          </div>
          <hr></hr>
          <AddressFields
            prefix="Billing"
            setBusinessDetail={setBusinessDetail}
            setBusinessDetailChanges={setBusinessDetailChanges}
            handleDisplayInEmailCheckbox={handleDisplayInEmailCheckbox}
            displayCityValue={businessDetail?.display_city}
            idDisplayCityCheckbox={true}
            address={
              addresses?.find((address) => address.address_type === "billing") ||
              addressFields
            }
          />
        </div>
      </div>
    </div>
  );
};

export default BusinessDetail;
