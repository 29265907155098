import {
  Box,
  Chip,
  CircularProgress,
  List,
  ListItem,
  ListItemText
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { getRequest } from "../../api";
import { ORDER_CHAT_STATUS } from "../../constants";
import { useDebounce } from "../../customHooks/useDebounce";

const BulkList = ({ ordersBase, userData, setSelectedRecipients }) => {
  const [searchInput, setSearchInput] = useState("");
  const [allOrders, setAllOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedRecipients, setSelectedRecipientsInternal] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const debouncedSearchInput = useDebounce(searchInput);

  useEffect(() => {
    const fetchOrders = async () => {
      setLoading(true);
      try {
        const orders = await getRequest(
          ordersBase,
          {
            filters: {
              chat_status: ORDER_CHAT_STATUS.ACTIVE,
            },
          },
          "user,messages.user",
        );
        setAllOrders(orders);
        setFilteredOrders(orders);
      } catch (error) {
        console.error("Error fetching orders:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchOrders();
  }, [userData]);

  useEffect(() => {
    let filtered = allOrders;
    if (debouncedSearchInput) {
      filtered = allOrders.filter((order) => {
        const customerName = order?.user?.name || "";
        const orderNumber = order.id ? order.id.toString() : "";
        const matchesName = customerName.toLowerCase().includes(debouncedSearchInput.toLowerCase());
        const matchesOrderNumber = orderNumber.includes(debouncedSearchInput);
        return matchesName || matchesOrderNumber;
      });
    }

    const selectedIds = selectedRecipients.map((recipient) => recipient.id);
    filtered = filtered.filter((order) => !selectedIds.includes(order.id));
    setFilteredOrders(filtered);
  }, [debouncedSearchInput, allOrders, selectedRecipients]);

  const handleSelectRecipient = (recipient) => {
    setSelectedRecipientsInternal([...selectedRecipients, recipient]);
    setSearchInput("");
    setFilteredOrders([]);
    setShowDropdown(false);
    setSelectedRecipients([...selectedRecipients, recipient]);
  };

  const handleRemoveRecipient = (recipientId) => {
    const newRecipients = selectedRecipients.filter((recipient) => recipient.id !== recipientId);
    setSelectedRecipientsInternal(newRecipients);
    setSelectedRecipients(newRecipients);
  };

  const handleInputFocus = () => {
    setShowDropdown(true);
  };

  const handleInputBlur = () => {
    setTimeout(() => setShowDropdown(false), 100);
  };

  return (
    <Box sx={{ position: "relative", width: "100%" }}>
      <Box display="flex" alignItems="center" flexWrap="wrap">
        {selectedRecipients.map((recipient) => (
          <Chip
            key={recipient.id}
            label={
              <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                <span style={{ color: "gray", fontSize: "12px" }}>Order #{recipient.id}</span>
                <span style={{ color: "black", fontSize: "14px", fontWeight: "bold" }}>
                  {recipient?.user?.name || "Unknown User"}
                </span>
              </div>
            }
            onDelete={() => handleRemoveRecipient(recipient.id)}
            style={{
              marginRight: 5,
              backgroundColor: "#F3F0E8",
              padding: "10px 6px",
              height: "auto",
            }}
          />
        ))}
        <input
          type="text"
          value={searchInput}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          onChange={(e) => setSearchInput(e.target.value)}
          placeholder=""
          style={{
            border: "none",
            outline: "none",
            backgroundColor: "transparent",
            boxShadow: "none",
          }}
        />
      </Box>

      {showDropdown && filteredOrders.length > 0 && (
        <Box
          sx={{
            position: "absolute",
            zIndex: 1000,
            backgroundColor: "white",
            border: "1px solid transparent",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
            width: "180px",
            maxHeight: "302px",
            overflowY: "auto",
            marginTop: "26px",
            borderRadius: "4px",
          }}
        >
          {loading ? (
            <CircularProgress size={24} />
          ) : (
            <List>
              {filteredOrders.map((order) => (
                <Box
                  sx={{
                    margin: "4px 14px",
                  }}>
                  <ListItem
                    sx={{
                      borderRadius: "8px",
                      "&:hover": {
                        backgroundColor: "#F3F0E880",
                      },
                      cursor: "pointer",
                      display: "flex",
                    }}
                    key={order.id}
                    onMouseDown={() => handleSelectRecipient(order)}
                  >
                    <ListItemText
                      primary={
                        <Box>
                          <div style={{ color: "#939291", fontSize: "14px" }}>#{order?.id}</div>
                          <div className="font-['Questrial'] font-extrabold text-[#272523] text-base" style={{ fontWeight: "400", fontSize: "16px",  }}>{order?.user?.name}</div>
                        </Box>
                      }
                    />
                  </ListItem>
                </Box>
              ))}
            </List>
          )}
        </Box>
      )}
    </Box>
  );
};

export default BulkList;
