import { postRequest, updateRequest, deleteRequest } from "../../api";
import { MESSAGE_STATUS } from "../../constants/MessageStatus";
import { MESSAGES } from "../../constants/BackendRoutes";

export const createDraftMessage = async (draft, ordersBase, storeId) => {
  const { content, user_id, order_id } = draft;
  await postRequest(`${ordersBase}/${draft?.order_id}${MESSAGES}`, {
    message: {
      content,
      user_id,
      order_id,
      store_id: storeId,
      status: MESSAGE_STATUS.DRAFT,
    },
  });
};

export const saveDraftMessage = async (draft, ordersBase, storeId) => {
  const { content, user_id, order_id, message_id } = draft;
  await updateRequest(`${ordersBase}/${order_id}${MESSAGES}/${message_id}`, {
    message: {
      content,
      user_id,
      order_id,
      store_id: storeId,
      status: MESSAGE_STATUS.DRAFT,
    },
  });
};

export const deleteDraftMessage = async (draft, ordersBase) => {
  await deleteRequest(`${ordersBase}/${draft?.order_id}${MESSAGES}/${draft?.message_id}`);
};

// call this destroyDraftMessageFromLS(); here
