import { AddCircleRounded } from '@mui/icons-material';
import { Button, Paper, TextField } from '@mui/material';
import React, { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getRequest, postRequest, updateRequest } from '../../api';
import { SendIcon } from "../../common/icons";
import { BULK_MESSAGES_CREATE, ORDERS, STORES } from '../../constants/BackendRoutes';
import { MESSAGE_STATUS } from '../../constants/MessageStatus';
import { useActionCable } from '../../customHooks/useActionCable';
import useHandleDraftMessage from '../../customHooks/useHandleDraftMessage';
import { setLoading } from "../../redux/loadingSlice";
import { addToast } from '../../redux/toastSlice';
import { categorizeMessages } from '../../utils';
import { resizeFile } from '../../utils/imageResizer';
import { destroyDraftMessageFromLS, storeDraftMessageToLS } from '../../utils/localStorage';
import MenuOptionButton from '../Button/MenuOptionButton';
import IncomingMessage from '../IncomingMessage';
import OutgoingMessage from '../OutgoingMessage';
import Attachment from './Attachment';

const textFieldInputProps = {
  sx: {
    maxHeight: '70px',
    overflowY: 'auto',
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      display: 'none',
    },
  },
};

const menuIconProps = {
  type: 'button',
  style: { color: '#4C8C4A' },
  sx: { p: '10px' },
  'aria-label': 'directions',
};

const Detail = forwardRef(function Detail({
  storeId,
  userData,
  ordersBase,
  createNewMessage,
  selectedRecipients,
  setCustomer,
  setIsDetailVisible,
  setCreateNewMessage,
}, ref) {
  const { id } = useParams();
  const [newMessages] = useActionCable('ChatSupportChannel', { order_id: id });
  const [content, setContent] = useState('');
  const [messages, setMessages] = useState([]);
  const [draftMessage, setDraftMessage] = useState({});
  const [prevMessages, setPrevMessages] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [_isQuoteModalOpen, setIsQuoteModalOpen] = useState(false);
  const fileInputRef = useRef(null);
  const handleDraftMessage = useHandleDraftMessage(ordersBase, storeId);

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch()

  const bulkMessagesURL = `${STORES}/${storeId}${ORDERS}${BULK_MESSAGES_CREATE}`;
  const userId = userData?.id;
  const orderURL = `${ordersBase}/${id}`;
  const orderMessagesURL = `${orderURL}/messages`;

  const buttonData = [
    { name: 'Attach repair quote', function: () => setIsQuoteModalOpen(true), disabled: true },
    { name: 'Add attachments', function: () => fileInputRef.current.click(), disabled: false },
  ];

  const updateMessages = useCallback(
    (newMessages) => {
      if (Object.keys(newMessages).length > 0) {
        setMessages((prevMessages) => {
          const todaysMessages = prevMessages['Today'] || [];
          todaysMessages.push(newMessages);

          return { ...prevMessages, Today: todaysMessages };
        });
      }
    },
    []
  );

  const fetchCustomer = useCallback(async () => {
    try {
      const data = await getRequest(`${orderURL}/customer`);
      setCustomer(data);
    } catch (error) {
      console.log('Error fetching customer:', error);
    }
  }, [orderURL, setCustomer]);

  const fetchMessages = useCallback(async () => {
    if (!createNewMessage && id) {
      try {
        const data = await getRequest(orderMessagesURL);
        const draft = data.find((message) => {
          return message.status === MESSAGE_STATUS.DRAFT && message.user_id === userId;
        });
        if (draft) setContent(draft.content);
        else setContent('');
        setDraftMessage(draft);
        const filteredData = data.filter((message) => message.status !== MESSAGE_STATUS.DRAFT);
        const categorizedRecords = categorizeMessages(filteredData);
        setMessages(categorizedRecords);
        setPrevMessages(
          Object.keys(categorizedRecords)
            .filter((key) => key !== 'Today' && key !== 'Yesterday')
            .sort((a, b) => new Date(a) - new Date(b))
        );
      } catch (error) {
        console.log('Error fetching messages:', error);
      }
    }
  }, [orderMessagesURL]);

  const setTextFieldContent = async() => {
    const data = await getRequest(orderMessagesURL);
    const draft = data.find((message) => {
      return message.status === MESSAGE_STATUS.DRAFT && message.user_id === userId;
    });
    if (draft) setContent(draft.content);
    else setContent('');
  };

  useEffect(() => {
    handleDraftMessage();
  }, [handleDraftMessage, location, navigate]);

  useEffect(() => {
    id && fetchCustomer();
  }, [id, fetchCustomer]);

  useEffect(() => {
    if (userData && !createNewMessage && id) fetchMessages();
  }, [id, userData, createNewMessage, fetchMessages]);

  useEffect(() => {
    if (newMessages) updateMessages(newMessages);
  }, [newMessages, updateMessages]);

  useEffect(() => {
    if (createNewMessage) {
      setContent('');
    } else {
      id && setTextFieldContent();
    }
  }, [createNewMessage]);

  const handleImageUpload = (e) => {
    const files = Array.from(e.target.files);
    const fileDetails = files.map((file) => ({
      url: URL.createObjectURL(file),
      name: file.name,
      file,
    }));
    setAttachments((prev) => [...prev, ...fileDetails]);
  };

  const removeImage = (index) => {
    setAttachments(attachments.filter((_, i) => i !== index));
  };

  const sendMessage = async () => {
    if (content.trim() && !createNewMessage) {
      await handleSingleMessage();
      destroyDraftMessageFromLS();
    } else if (createNewMessage && content.trim()) {
      await handleBulkMessage();
    }
  };

  const handleSingleMessage = async () => {
    try {
      const formData = new FormData();
      formData.append('message[content]', content);
      formData.append('message[order_id]', id);
      formData.append('message[user_id]', userId);

      const headers = {
        "Content-Type": "multipart/form-data"
      }

      for (const image of attachments) {
        const resizedImage = await resizeFile(image.file);
        formData.append('message[attachments][]', resizedImage);
      }

      if (draftMessage) {
        formData.append('message[status]', MESSAGE_STATUS.SENT);
        await updateRequest(`${orderMessagesURL}/${draftMessage?.id}`, formData, headers);
      } else {
        await postRequest(orderMessagesURL, formData, headers);
      }
      resetMessageState();
    } catch (error) {
      console.log('Error sending message:', error);
    }
  };

  const handleBulkMessage = async () => {
    try {
      dispatch(setLoading(true));
      const messages = await Promise.all(
        selectedRecipients.map(async (recipient) => {
          const resizedAttachments = await Promise.all(
            attachments.map(async (attachment) => {
              const resizedImage = await resizeFile(attachment.file);
              return resizedImage;
            })
          );

          return {
            content,
            order_id: recipient?.id,
            user_id: userId,
            attachments: resizedAttachments
          };
        })
      );

      const formData = new FormData();
      messages.forEach((message, index) => {
        formData.append(`messages[${index}][content]`, message.content);
        formData.append(`messages[${index}][order_id]`, message.order_id);
        formData.append(`messages[${index}][user_id]`, message.user_id);

        message.attachments.forEach((attachment) => {
          formData.append(`messages[${index}]attachments][]`, attachment);
        });
      });

      await postRequest(bulkMessagesURL, formData, {
        'Content-Type': 'multipart/form-data',
      });
      resetMessageState();
      dispatch(setLoading(false));
      dispatch(addToast('Messages sent successfully!'));
      setCreateNewMessage(false);
      setIsDetailVisible(false);
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(addToast('Something went wrong, please try again.'));
    }
  };

  const resetMessageState = () => {
    setAttachments([]);
    setContent('');
    setDraftMessage({});
    fetchMessages();
  };

  return (
    <div className="flex flex-col h-full">
      {id &&
        <div ref={ref} className="flex flex-col gap-5 mt-2 overflow-x-hidden sm:overflow-auto">
          {prevMessages.map((date) => renderMessages(date))}
          {renderMessages('Yesterday')}
          {renderMessages('Today')}
        </div>
      }

      {(id || createNewMessage) &&
        <div className="flex gap-1 mt-auto p-4 items-end" >
          <Paper className="w-3/5 p-1 flex flex-col flex-grow">
            <div className="flex items-center w-full gap-2">
              <div className="flex-grow">
                <TextField
                  value={content}
                  onChange={handleContentChange}
                  placeholder="Type your message here..."
                  multiline
                  variant="outlined"
                  fullWidth
                  InputProps={textFieldInputProps}
                />
                <input type="file" ref={fileInputRef} style={{ display: 'none' }} accept="image/*" multiple onChange={handleImageUpload} />
              </div>
              <MenuOptionButton data={buttonData} icon={<AddCircleRounded />} isMenuHorizontal={true} iconButtonProps={menuIconProps} />
            </div>
            {attachments.length > 0 && (
              <div className="mt-2 flex flex-wrap gap-2">
                {renderAttachments()}
              </div>
            )}
          </Paper>
          <Button className='min-h-[67px]' variant="containedPrimary" onClick={sendMessage} startIcon={<SendIcon />}>
            Send message
          </Button>
        </div>
      }
    </div>
  );

  function renderMessages(date) {
    if (!createNewMessage && messages[date]?.length > 0) {
      return (
        <div key={date}>
          <h3 className="text-[#939291] text-center text-xs mb-9">{date}</h3>
          {messages[date].map((message, index) => (
            <div key={`${message.id}-${index}`}>
              {message?.user_id === userId ? (
                <OutgoingMessage text={message.content} time={message.created_at} attachments={message.attachments_data} />
              ) : (
                <IncomingMessage text={message.content} time={message.created_at} attachments={message.attachments_data} />
              )}
            </div>
          ))}
        </div>
      );
    }
    return null;
  }

  function renderAttachments() {
    return (
      <div className="flex gap-2 mt-2 max-h-[64px] overflow-x-auto">
        {attachments.map((attachment, index) => (
          <Attachment key={index} imageUrl={attachment.url} fileName={attachment.name} onRemove={removeImage} index={index} />
        ))}
      </div>
    );
  }

  function handleContentChange(e) {
    const value = e.target.value;
    setContent(value);
    if (!createNewMessage) {
      storeDraftMessageToLS(JSON.stringify({
        order_id: id,
        content: value,
        user_id: userId,
        status: MESSAGE_STATUS.DRAFT,
        message_id: draftMessage?.id,
      }));
    }
  }
});

export default Detail;
