import { Button } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { Link, Outlet } from "react-router-dom";
import { logout } from "../../redux/authActions";
import Logo from "../../images/Logo.svg"
import { CUSTOMER_ROUTES, ROOT } from "../../constants/FrontendRoutes";
import MenuOptionButton from "../Button/MenuOptionButton";
import { addToast } from "../../redux/toastSlice";
import { ArrowDownIcon } from "../../common/icons";

const CustomerLayout = () => {
  const dispatch = useDispatch()

  const buttonConfig = [
    {
      name: "My account",
      function: () => dispatch(addToast("Navigate to Customer profile settings")),
    },
    {
      name: "Logout",
      function: () => dispatch(logout())
    }
  ];

  return (
    <div className="bg-[#F8F8F8] h-screen">
      <div className="mx-auto max-w-[1280px]">
        <div className="p-5 flex justify-between items-center w-full border-b">
          <div className="flex items-center gap-5">
            <Link to={CUSTOMER_ROUTES.ORDERS_OVERVIEW}>
              <img alt="logo" src={Logo} className="w-9 lg:w-10 h-auto" />
            </Link>
            <p className="text-[#272523] text-base font-normal font-['Questrial'] leading-snug">
              My Orders
            </p>
          </div>
          <MenuOptionButton
            title="My account"
            data={buttonConfig}
            icon={<ArrowDownIcon fill="black" />}
            isMenuHorizontal={false}
          />
        </div>
        <Outlet />
      </div>
    </div>
  );
};

export default CustomerLayout;
