import { Checkbox, FormControlLabel, TextField } from "@mui/material";
import React from "react";
import InputMask from "react-input-mask";
import { CHECKBOX_DISPLAY_IN_EMAIL, COUNTRIES, US_STATES } from "../../constants";
import Selector from "../Form/Field/AutoComplete";

const AddressFields = ({
  prefix,
  address,
  displayCityValue,
  idDisplayCityCheckbox,
  setBusinessDetail,
  setBusinessDetailChanges,
  handleDisplayInEmailCheckbox
}) => {
  const handleChange = (field, value) => {
    setBusinessDetail((prevDetail) => {
      const existingAddressIndex = prevDetail.addresses?.findIndex(
        (addr) => addr.address_type === prefix.toLowerCase()
      );

      const updatedAddresses = [...prevDetail.addresses];

      if (existingAddressIndex !== -1) {
        updatedAddresses[existingAddressIndex] = {
          ...updatedAddresses[existingAddressIndex],
          [field]: value,
        };
      } else {
        updatedAddresses.push({
          address_type: prefix.toLowerCase(),
          line: "",
          zipcode: "",
          city: "",
          state: "",
          country: "",
          [field]: value,
        });
      }

      setBusinessDetailChanges((prev) => ({
        ...prev,
        addresses: updatedAddresses,
      }));

      return {
        ...prevDetail,
        addresses: updatedAddresses,
      };
    });
  };

  const handleFieldChange = (event) => {
    const { name, value } = event.target;
    handleChange(name, value);
  };

  const handleSelectorChange = (field) => (_, value) => {
    handleChange(field, value?.value);
  };

  return (
    <React.Fragment>
      <div className="flex flex-col sm:flex-row gap-4">
        <div className="flex flex-col gap-2 w-full">
          <div className="w-40 h-[22px] text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
            {prefix} address
          </div>
          <TextField
            name="line"
            placeholder="Please enter"
            variant="outlined"
            value={address.line}
            onChange={handleFieldChange}
          />
        </div>
        <div className="flex flex-col gap-2 w-full">
          <div className="w-40 h-[22px] text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
            Zipcode
          </div>
          <InputMask
            mask="99999"
            value={address.zipcode}
            onChange={handleFieldChange}
          >
            {() => (
              <TextField
                type="tel"
                name="zipcode"
                placeholder="12345"
                className="flex-1"
                variant="outlined"
              />
            )}
          </InputMask>
        </div>
      </div>
      <div className="flex flex-col sm:flex-row gap-4">
        <div className="flex flex-col gap-2 w-full">
          <div className="w-40 h-[22px] text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
            City
          </div>
          <TextField
            name="city"
            placeholder="New York"
            variant="outlined"
            value={address.city}
            onChange={handleFieldChange}
          />
        </div>
        <div className="flex flex-col gap-2 w-full">
          <div className="w-40 h-[22px] text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
            State
          </div>
          <Selector
            name="state"
            label="Please select"
            size="large"
            options={US_STATES}
            onChange={handleSelectorChange("state")}
            value={US_STATES.find((state) => state.value === address.state)}
          />
        </div>
        <div className="flex flex-col gap-2 w-full">
          <div className="w-40 h-[22px] text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
            Country
          </div>
          <Selector
            name="country"
            label="Please select"
            size="large"
            options={COUNTRIES}
            onChange={handleSelectorChange("country")}
            value={COUNTRIES.find(
              (country) => country.value === address.country
            )}
          />
        </div>
      </div>
      {idDisplayCityCheckbox &&
          <FormControlLabel
            control={
              <Checkbox
                name="display_city"
                inputProps={{ "aria-label": "controlled" }}
                checked={displayCityValue}
                onChange={handleDisplayInEmailCheckbox}
              />
            }
            label={CHECKBOX_DISPLAY_IN_EMAIL}
          />
        }
    </React.Fragment>
  );
};

export default AddressFields;
