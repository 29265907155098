import React, { useEffect } from 'react';
import { DraftIcon, FlagIcon, InboxIcon, SentIcon, TrashIcon } from '../../common/icons';
import { ORDER_CHAT_TYPE_KEYS } from '../../constants';
import useHandleDraftMessage from '../../customHooks/useHandleDraftMessage';

const chatTypeItems = [
  {
    label: ORDER_CHAT_TYPE_KEYS.INBOX,
    icon: <InboxIcon className="w-7 h-7 hidden sm:block" />,
  },
  {
    label: ORDER_CHAT_TYPE_KEYS.FLAGGED,
    icon: <FlagIcon className="w-7 h-7 hidden sm:block" />,
  },
  {
    label: ORDER_CHAT_TYPE_KEYS.SENT,
    icon: <SentIcon className="w-7 h-7 hidden sm:block" />,
  },
  {
    label: ORDER_CHAT_TYPE_KEYS.DRAFT,
    icon: <DraftIcon className="w-7 h-7 hidden sm:block" />,
  },
  {
    label: ORDER_CHAT_TYPE_KEYS.TRASH,
    icon: <TrashIcon className="w-7 h-7 hidden sm:block" />,
  },
];

function Type({ storeId, ordersBase, selectedListItem, fetchOrders, setIsDetailVisible, setSelectedListItem, setCreateNewMessage }) {
  const handleDraftMessage = useHandleDraftMessage(ordersBase, storeId);

  useEffect(() => {
    setIsDetailVisible(false);
    setCreateNewMessage(false);
    handleDraftMessage();
    fetchOrders(selectedListItem);
  }, [selectedListItem]);

  const renderChatTypeItems = () => {
    return chatTypeItems.map((item) => (
      <div
        key={item.label}
        className={`flex flex-row items-center gap-2 sm:gap-7 p-2 cursor-pointer ${
          selectedListItem === item.label && 'bg-[#F3F0E8] rounded-lg text-black'
        }`}
        onClick={() => setSelectedListItem(item.label)}>
        {item.icon}
        <span
          className={`${
            selectedListItem === item.label &&
            ' text-black underline decoration-4 underline-offset-8 decoration-green-600 sm:no-underline'
          }`}>
          {item.label}
        </span>
      </div>
    ));
  };

  return (
    <div className="flex flex-col px-1 sm:px-0 sm:items-center gap-3 text-[#939291] my-3 sm:mt-5">
      <div className="flex flex-row sm:flex-col gap-5 sm:gap-2 justify-between">
      {renderChatTypeItems()}
      </div>
    </div>
  );
}

export default Type;
