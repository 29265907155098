import React, { useState } from "react";
import BasicTabs, { CustomTabPanel } from "../../common/components/Tabs";
import { ORDER_HISTORY_TAB_LIST } from "../../constants";
import { useNavigate, useLocation } from "react-router-dom";
import OrderAccordion from "./OrderAccordion";

const OrderHistory = ({ userData, OrderDisplay = OrderAccordion }) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const navigate = useNavigate();

  const orders = userData?.orders || [];

  const filterOrdersByStatus = (statuses) =>
    orders.filter((order) => statuses.includes(order.status));

  const quoteOrders = filterOrdersByStatus(["quote"]);
  const activeOrders = filterOrdersByStatus([
    "new_order",
    "shipping",
    "in_progress",
    "delayed",
  ]);
  const pastOrders = filterOrdersByStatus(["repaired", "delivered"]);

  const initialTab = searchParams.get("tab") || ORDER_HISTORY_TAB_LIST[0].value;
  const [currentTab, setCurrentTab] = useState(initialTab);

  const handleTabChange = (newValue) => {
    const newTab = ORDER_HISTORY_TAB_LIST[newValue].value;
    setCurrentTab(newTab);
    searchParams.set("tab", newTab);
    navigate({ search: searchParams.toString() });
  };

  const renderOrders = (orders) =>
    orders.length > 0 ? (
      <div className="flex flex-col gap-[24px]">
        {orders.map((order) => (
          <OrderDisplay key={order.id} order={order} />
        ))}
      </div>
    ) : (
      <p className="px-3 py-10 text-center text-[#837d77]">
        No order(s) to display
      </p>
    );

  return (
    <section className="bg-white px-5 lg:px-8 py-[24px] flex flex-col gap-[16px] rounded-[8px] w-full">
      <div className="font-['Montserrat'] text-[24px] font-[700] text-[#272523]">
        Order history
      </div>
      <BasicTabs
        list={ORDER_HISTORY_TAB_LIST.map((tab) => tab.label)}
        setValue={handleTabChange}
        value={ORDER_HISTORY_TAB_LIST.findIndex(
          (tab) => tab.value === currentTab
        )}
      />
      <CustomTabPanel
        value={ORDER_HISTORY_TAB_LIST.findIndex(
          (tab) => tab.value === currentTab
        )}
        index={0}
      >
        {renderOrders(orders)}
      </CustomTabPanel>
      <CustomTabPanel
        value={ORDER_HISTORY_TAB_LIST.findIndex(
          (tab) => tab.value === currentTab
        )}
        index={1}
      >
        {renderOrders(quoteOrders)}
      </CustomTabPanel>
      <CustomTabPanel
        value={ORDER_HISTORY_TAB_LIST.findIndex(
          (tab) => tab.value === currentTab
        )}
        index={2}
      >
        {renderOrders(activeOrders)}
      </CustomTabPanel>
      <CustomTabPanel
        value={ORDER_HISTORY_TAB_LIST.findIndex(
          (tab) => tab.value === currentTab
        )}
        index={3}
      >
        {renderOrders(pastOrders)}
      </CustomTabPanel>
    </section>
  );
};

export default OrderHistory;
