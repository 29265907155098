import CircleIcon from '@mui/icons-material/Circle';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { updateRequest } from '../../api';
import { ORDER_CHAT_TYPE_VALUES } from '../../constants';
import { MARK_ALL_MESSAGES_READ, STORES } from '../../constants/BackendRoutes';
import { MESSAGES_ORDERS_DETAILS } from '../../constants/FrontendRoutes';
import { useActionCable } from '../../customHooks/useActionCable';
import { formatTimeDifference } from '../../utils';

const List = ({
  storeId,
  userData,
  ordersData,
  searchInput,
  setCreateNewMessage,
  isAllMessagesSelected,
  isUnreadMessagesSelected,
  setIsMessageFlagged,
  setIsDetailVisible
}) => {
  const { id } = useParams();
  const [orders, setOrders] = useState([]);
  const [allOrders, setAllOrders] = useState([]);
  const [newMessage] = useActionCable('OrdersListChannel');
  const orderId = parseInt(id);

  useEffect(() => {
    setOrders(ordersData);
    setAllOrders(ordersData);
  },[ordersData]);

  useEffect(() => {
    let filteredOrders = allOrders;

    if (!isAllMessagesSelected && !isUnreadMessagesSelected) {

      filteredOrders = [];
    } else {
      if (isUnreadMessagesSelected && !isAllMessagesSelected) {

        filteredOrders = allOrders.filter(order => order.highlighted);
      } else if (isAllMessagesSelected && !isUnreadMessagesSelected) {

        filteredOrders = allOrders;
      } else if (isUnreadMessagesSelected && isAllMessagesSelected) {

        filteredOrders = allOrders;
      }

      filteredOrders = filteredOrders.filter((order) => {
        const matchesSearch =
          order.customerName.toLowerCase().includes(searchInput.toLowerCase()) ||
          order.orderNumber.toString().includes(searchInput);

        return matchesSearch;
      });
    }

    setOrders(filteredOrders);
  }, [searchInput, isAllMessagesSelected, isUnreadMessagesSelected, allOrders]);

  useEffect(() => {
    if (newMessage.order_id === orderId && newMessage.user_id !== userData.id) {
      markMessagesRead(id);
    }
    setOrders(
      orders.map((order) => {
        if (order.id === newMessage.order_id) {
          return {
            ...order,
            highlighted: orderId !== newMessage.order_id,
            message: newMessage.content,
            time: newMessage.created_at,
          };
        } else {
          return order;
        }
      }),
    );
  }, [newMessage]);

  const markMessagesRead = async (orderID) => {
    await updateRequest(
      `${STORES}/${storeId}/orders/${orderID}${MARK_ALL_MESSAGES_READ}`,
    );
  };

  const handleClick = async (orderId) => {
    const order = orders.find((order) => order.id === orderId);
    if (order.highlighted) {
      await markMessagesRead(orderId);
    }
    setOrders(
      orders.map((msg) => (msg.orderNumber === orderId ? { ...msg, highlighted: false } : msg)),
    );
    setIsMessageFlagged(order?.chatStatus === ORDER_CHAT_TYPE_VALUES.FLAGGED);
  };

  return (
    <div className="flex flex-col">
      {orders.map((order) => {
        return (
          <Link
            className={`${order.id === orderId ? 'sm:bg-[#F3F0E8]' : ''}`}
            key={order.id}
            to={MESSAGES_ORDERS_DETAILS.replace(':id', order?.id)}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              handleClick(order.id);
              setCreateNewMessage(false);
              setIsDetailVisible(true);
            }}>
            <div className={'mx-5 px-0 py-2 sm:py-5 border-b border-solid border-[#F3F0E8] '}>
              <div className="flex justify-between text-[#939291">
                <div className="text-stone-400 text-[11px] font-normal">
                  Order #{order.orderNumber}
                </div>
                <div className="text-right text-stone-400 text-xs font-normal font-['Questrial'] leading-none">
                  {formatTimeDifference(order.time)}
                </div>
              </div>
              <div className="flex justify-between relative">
                <div className="text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
                  {order.customerName}
                </div>
                {order.highlighted && (
                  <div
                    style={{
                      position: 'absolute',
                      transform: 'translate(0, 5px)',
                      left: '-5%',
                    }}
                  >
                    <CircleIcon
                      sx={{
                        width: '10px',
                        height: '10px',
                        color: '#D84141',
                        position: 'absolute',
                      }}
                    />
                  </div>
                )}
              </div>
              <div className="text-xs mt-2">{order.message}</div>
            </div>
          </Link>
        );
      })}
    </div>
  );
};

export default List;
