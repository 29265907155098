import { FilterList, Search } from '@mui/icons-material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, Checkbox, IconButton, ListItemText, Menu, MenuItem } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useReactToPrint } from "react-to-print";
import { getRequest, updateRequest } from '../api';
import { EditIcon, FlagIcon } from '../common/icons';
import MenuOptionButton from '../components/Button/MenuOptionButton';
import EntityPage from '../components/EntityPage';
import SearchInput from '../components/Form/Field/SearchInput';
import BulkList from '../components/Message/BulkList';
import MessageDetail from '../components/Message/Detail';
import MessageList from '../components/Message/List';
import MessageType from '../components/Message/Type';
import { ORDER_CHAT_STATUS, ORDER_CHAT_TYPE_FILTERS, ORDER_CHAT_TYPE_KEYS, ORDER_CHAT_TYPE_VALUES } from '../constants';
import { ORDERS, STORES } from '../constants/BackendRoutes';
import { MESSAGES } from '../constants/FrontendRoutes';
import { MESSAGE_STATUS } from '../constants/MessageStatus';
import { useDebounce } from '../customHooks/useDebounce';
import useHandleDraftMessage from '../customHooks/useHandleDraftMessage';
import { selectCurrentUser } from '../redux/authSlice';
import { setLoading } from "../redux/loadingSlice";
import { addToast } from '../redux/toastSlice';

const iconButtonStyles = {
  '&:hover': {
    bgcolor: '#DCFCE7',
    color: '#16a34a',
  },
};
const iconsStyles = "hover:bg-green-100/70 hover:text-green-600 rounded-lg w-7 h-7";

const Messages = () => {
  const { id } = useParams();
  const printRef = useRef();
  const userData = useSelector(selectCurrentUser);

  const userId = userData?.id;
  const storeId = userData?.stores?.[0]?.id;
  const ordersBase = `${STORES}/${storeId}${ORDERS}`;

  const [customer, setCustomer] = useState({});

  const [searchInput, setSearchInput] = useState('');
  const [selectedListItem, setSelectedListItem] = useState(ORDER_CHAT_TYPE_KEYS.INBOX);

  const [anchorEl, setAnchorEl] = useState(null);

  const debouncedSearchValue = useDebounce(searchInput);
  const handleDraftMessage = useHandleDraftMessage(ordersBase, storeId);

  const [isSearchEnabled, setIsSearchEnabled] = useState(false);
  const [isDetailVisible, setIsDetailVisible] = useState(false);
  const [isMessageFlagged, setIsMessageFlagged] = useState(false);
  const [createNewMessage, setCreateNewMessage] = useState(false);
  const [isAllMessagesSelected, setIsAllMessagesSelected] = useState(true);
  const [isUnreadMessagesSelected, setIsUnreadMessagesSelected] = useState(false);

  const [orders, setOrders] = useState([]);
  const [selectedRecipients, setSelectedRecipients] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleFilterMenuClick = (event) => setAnchorEl(event.currentTarget);
  const handleFilterMenuClose = () => setAnchorEl(null);

  const handleAllMessagesToggle = () => setIsAllMessagesSelected((prev) => !prev);
  const handleUnreadMessagesToggle = () => setIsUnreadMessagesSelected((prev) => !prev);

  const updateMessageStatus = async (status, message) => {
    try {
      dispatch(setLoading(true));
      await updateRequest(`${STORES}/${storeId}/orders/${id}`, { chat_status: status });
      dispatch(addToast(message));
      fetchOrders(selectedListItem);
      setIsDetailVisible(false);
      navigate(MESSAGES);
    } catch (err) {
      setIsDetailVisible(true);
      dispatch(addToast(err));
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  let buttonData = [
    { name: 'Delete', function: () => updateMessageStatus(ORDER_CHAT_STATUS.TRASH, 'Conversation moved to Trash.'), disabled: false },
    { name: 'Print', function: handlePrint, disabled: false },
  ];

  if (selectedListItem === 'Trash') {
    buttonData = buttonData.filter(button => button.name !== 'Delete');
    buttonData.push({ name: 'Move to Inbox', function: () => updateMessageStatus(ORDER_CHAT_STATUS.ACTIVE, 'Conversation moved to Inbox.'), disabled: false });
  }

  const fetchOrders = async (selectedListItem) => {
    try {
      const fetchedOrders = await fetchAndMapOrders(selectedListItem);
      setOrders(fetchedOrders);
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };

  const fetchAndMapOrders = async (selectedListItem) => {
    try {
      const selectedChatStatus = ORDER_CHAT_TYPE_FILTERS.find(item => item.label === selectedListItem)?.value;
      const repairOrders = await getRequest(`${STORES}/${storeId}/orders`, {
        filters: {
          chat_status: selectedChatStatus || ORDER_CHAT_TYPE_VALUES.ACTIVE,
        },
      }, "user,messages.user");

      const orders = await Promise.all(repairOrders.map(async (order) => {
        const messages = order?.messages?.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
        const lastMessage = getLastMessage(messages);
        let highlighted = lastMessage?.user?.id === userData?.id ? false : !lastMessage?.is_read;

        if (!lastMessage) highlighted = false;

        return {
          id: order.id,
          orderNumber: order.id,
          chatStatus: order?.chat_status,
          customerName: order.user.name,
          message: lastMessage?.content,
          messageId: lastMessage?.id,
          time: lastMessage?.created_at,
          highlighted,
        };
      }));

      return orders;
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };

  const getLastMessage = (messages) => {
    if (messages.length === 0) {
      return null;
    }
    const lastMessage = messages[messages.length - 1];
    if (lastMessage?.status === MESSAGE_STATUS.DRAFT) {
      if (lastMessage.user_id === userId) {
        return lastMessage;
      } else {
        return messages[messages.length - 2];
      }
    } else {
      return lastMessage;
    }
  };

  useEffect(() => {
    handleDraftMessage();
  }, [selectedListItem]);

  useEffect(() => {
    if (userData && !createNewMessage) fetchOrders(selectedListItem);
  }, [userData, selectedListItem, createNewMessage]);

  useEffect(() => {
    navigate(MESSAGES);
  }, [selectedListItem]);

  return (
    <div className="bg-[#F8F8F8] overflow-hidden">
      <EntityPage title="Messages" classNameLayout="hidden md:flex p-0 md:p-5" childrenLayout="p-0">
        <div className={`grid sm:border-t border-[#F3F0E8] grid-cols-5 grid-rows-auto`}>
          <div className={`items-center flex flex-row justify-between px-4 p-2 py-4 sm:p-4 col-span-5 sm:col-span-2 ${(id || createNewMessage) && 'hidden sm:flex'}`}>
            <div className="text-[#272523] text-xs sm:text-lg font-semibold font-['Montserrat']">
              All Messages
            </div>
            <div className="flex items-center" style={{ color: '#939291' }}>
              {!isSearchEnabled ? (
                <IconButton
                  onClick={() => setIsSearchEnabled(true)}
                  size="small"
                  sx={iconButtonStyles}
                >
                  <Search className={iconsStyles} />
                </IconButton>
              ) : (
                <SearchInput
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                  placeholder="Search"
                  isOnClearEnabled={true}
                  onClear={() => { setIsSearchEnabled(false); setSearchInput(""); }}
                  width="200px"
                />
              )}
              <IconButton
                onClick={() => {
                  setCreateNewMessage(true);
                  setIsDetailVisible(true);
                }}
                size="small"
                sx={iconButtonStyles}
              >
                <EditIcon className={iconsStyles} fill={createNewMessage ? "green" : "gray"} />
              </IconButton>

              <IconButton
                onClick={handleFilterMenuClick}
                size="small"
                sx={iconButtonStyles}
              >
                <FilterList className={iconsStyles} />
              </IconButton>

              <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleFilterMenuClose}>
                <MenuItem onClick={handleAllMessagesToggle}>
                  <Checkbox checked={isAllMessagesSelected} />
                  <ListItemText primary="All Messages" />
                </MenuItem>
                <MenuItem onClick={handleUnreadMessagesToggle}>
                  <Checkbox checked={isUnreadMessagesSelected} />
                  <ListItemText primary="Unread Messages" />
                </MenuItem>
              </Menu>
            </div>
          </div>
          <div className="flex flex-col sm:hidden col-span-5">
            {isDetailVisible && <div className="bg-transparent flex flex-row py-4">
              {(createNewMessage || id) &&
                <div className="basis-[10%] flex justify-center items-center">
                  <Link to={MESSAGES} onClick={() => setCreateNewMessage(false)}>
                    <ChevronLeftIcon className="text-gray-600" />
                  </Link>
                </div>
              }

              {!createNewMessage && id &&
                <div className="basis-[70%] flex flex-col">
                  <div className="text-gray-500 text-xs">Order# {id}</div>
                  <div className="text-base">{customer?.name}</div>
                </div>
              }

              {createNewMessage &&
                <React.Fragment>
                  <p className="font-['Montserrat'] font-normal text-base text-[#272523] flex pr-1 text-nowrap" style={{ lineHeight: '1.5em' }}>Send to:</p>
                  <BulkList ordersBase={ordersBase} userData={userData} setSelectedRecipients={setSelectedRecipients}/>
                </React.Fragment>
              }

              {!createNewMessage && id &&
                <div className="basis-[20%] flex justify-center gap-2 items-center">
                  <IconButton onClick={() => updateMessageStatus(
                    isMessageFlagged ? ORDER_CHAT_STATUS.ACTIVE : ORDER_CHAT_STATUS.FLAG,
                    isMessageFlagged ? 'Conversation moved to Inbox.' : 'Conversation marked as flagged.'
                  )}>
                    <FlagIcon className="w-7 h-7" fill={!isMessageFlagged ? 'gray' : 'green'} />
                  </IconButton>
                  <MenuOptionButton data={buttonData} icon={<MoreVertIcon />} />
                </div>
              }
            </div>}
          </div>
          <div className="sm:flex hidden rounded-tl-lg rounded-tr-lg border-[#F3F0E8] p-4 col-span-3 bg-white">
            <div className="flex flex-col w-full">
              {!createNewMessage ? (
                isDetailVisible && (
                  <div className="flex justify-between">
                    <span className="text-[#272523] text-lg font-semibold font-['Montserrat'] text-center items-center flex">
                      {customer?.name}
                    </span>
                    <div className="flex gap-2 items-center">
                      <IconButton onClick={() => updateMessageStatus(
                        isMessageFlagged ? ORDER_CHAT_STATUS.ACTIVE : ORDER_CHAT_STATUS.FLAG,
                        isMessageFlagged ? 'Conversation moved to Inbox.' : 'Conversation marked as flagged.'
                      )}>
                        <FlagIcon className="w-7 h-7" fill={!isMessageFlagged ? 'gray' : 'green'} />
                      </IconButton>
                      <MenuOptionButton data={buttonData} icon={<MoreVertIcon />} />
                    </div>
                  </div>
                )
              ) : (
              <div className="flex flex-row justify-center items-center h-full">
                <p className="font-['Montserrat'] font-normal text-base text-[#272523] flex pr-1 text-nowrap" style={{ lineHeight: '1.5em' }}>Send to:</p>
                <BulkList
                  ordersBase={ordersBase}
                  userData={userData}
                  setSelectedRecipients={setSelectedRecipients}
                />
              </div>
              )}
            </div>
          </div>

          <Box className={`flex flex-col sm:flex-row justify-between md:border-t border-[#F3F0E8] sm:ps-6 col-span-5 sm:col-span-2 bg-white md:bg-transparent ${(id || createNewMessage) && 'hidden sm:flex'}`}>
            <MessageType
              storeId={storeId}
              ordersBase={ordersBase}
              selectedListItem={selectedListItem}
              fetchOrders={fetchOrders}
              setIsDetailVisible={setIsDetailVisible}
              setSelectedListItem={setSelectedListItem}
              setCreateNewMessage={setCreateNewMessage}
            />
            <Box sx={{ height: `calc(100vh - 170px)` }}
              className="border-l border-r border-[#F3F0E8] overflow-auto flex flex-col my-2 sm:my-0 gap-2 sm:w-3/5">
              <MessageList
                storeId={storeId}
                userData={userData}
                setCreateNewMessage={setCreateNewMessage}
                ordersData={orders}
                searchInput={debouncedSearchValue}
                setIsMessageFlagged={setIsMessageFlagged}
                selectedListItem={selectedListItem}
                isAllMessagesSelected={isAllMessagesSelected}
                isUnreadMessagesSelected={isUnreadMessagesSelected}
                setIsDetailVisible={setIsDetailVisible}
              />
            </Box>
          </Box>

          <React.Fragment>
          <Box
            sx={{
              height: `calc(100vh - 170px)`,
            }}
            className="sm:block overflow-auto border-t border-[#F3F0E8] p-4 col-span-5 sm:col-span-3 bg-white">
              {isDetailVisible &&
                <MessageDetail
                  ref={printRef}
                  storeId={storeId}
                  userData={userData}
                  ordersBase={ordersBase}
                  createNewMessage={createNewMessage}
                  selectedRecipients={selectedRecipients}
                  setCustomer={setCustomer}
                  setIsDetailVisible={setIsDetailVisible}
                  setCreateNewMessage={setCreateNewMessage}
                />
              }
            </Box>
          </React.Fragment>
        </div>
      </EntityPage>
    </div>
  );
};

export default Messages;
