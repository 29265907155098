import { useCallback } from 'react';
import { createDraftMessage, saveDraftMessage, deleteDraftMessage } from '../utils/axios/draftMessage';
import { destroyDraftMessageFromLS, retrieveDraftMessageFromLS } from '../utils/localStorage';

const useHandleDraftMessage = (ordersBase, storeId) => {
  const handleDraftMessage = useCallback(() => {
    const draft = retrieveDraftMessageFromLS();
    if (!draft) return;

    const isContent = draft.content?.trim() !== '';
    const { message_id } = draft;

    if (message_id) {
      isContent ? saveDraftMessage(draft, ordersBase, storeId) : deleteDraftMessage(draft, ordersBase);
    } else if (!message_id && isContent) {
      createDraftMessage(draft, ordersBase, storeId);
    }
    destroyDraftMessageFromLS();
  }, [ordersBase, storeId]);

  return handleDraftMessage;
};

export default useHandleDraftMessage;
