import React, { useState, useEffect } from "react";
import { getRequest } from "../api";
import { useDispatch } from "react-redux";
import { addToast } from "../redux/toastSlice";
import OrderHistory from "../components/CustomerDetails/OrderHistory";
import Chip from "../components/Chip";
import { convertDateFormat, formatDate } from "../utils";
import { useNavigate } from "react-router-dom";
import { IconRight } from "../components/CustomerDetails/MessageHistory";

const CustomOrderDisplay = ({ order }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <div className="flex flex-col md:flex-row justify-between items-center border rounded lg:min-w-[768px]">
      <div className="flex flex-col justify-start items-start flex-grow gap-2 border-b md:border-r p-5 w-full">
        <p className="text-[#939291] text-base font-normal font-['Questrial'] leading-snug">
          Ordered {convertDateFormat(order?.created_at)}
        </p>
        <div className="flex justify-start items-center gap-2">
          <p className="text-[#939291] text-base font-normal font-['Questrial'] leading-snug">
            Order ID:
          </p>
          <div className="flex justify-start items-center gap-2">
            <p className="text-[#272523] text-lg font-semibold font-['Montserrat'] leading-[25.20px]">
              #{order?.id}
            </p>
            <Chip status={order?.status} />
          </div>
        </div>

        {order?.shipping_labels?.length > 0 && (
          <div className="flex justify-start gap-1">
            <div className="text-[#939291] text-base font-normal font-['Questrial'] leading-snug">
              Tracking #
            </div>
            <div className="text-[#272523] text-base font-normal font-['Questrial'] leading-snug">
              {["repaired", "delivered"].includes(order?.status) &&
              order?.shipping_labels.some(
                (label) => label?.shipping_leg === "leg_3"
              )
                ? order?.shipping_labels?.find(
                    (label) => label?.shipping_leg === "leg_3"
                  )?.tracking_number
                : order.shipping_labels.find(
                    (label) => label?.shipping_leg === "leg_2"
                  )?.tracking_number}
            </div>
          </div>
        )}

        {order?.estimated_completion && (
          <div className="flex justify-start gap-1">
            <div className="text-[#939291] text-base font-normal font-['Questrial'] leading-snug">
              Next date:
            </div>
            <div className="grow shrink basis-0 text-[#12b669] text-base font-normal font-['Questrial'] leading-snug">
              {order?.status === "repaired" || order?.status === "delivered"
                ? "-"
                : `Estimated completion: ${formatDate(
                    order?.estimated_completion
                  )}`}
            </div>
          </div>
        )}
        <div className="justify-start gap-1 flex">
          <div className="text-[#939291] text-base font-normal font-['Questrial'] leading-snug">
            Order total:
          </div>
          {order?.net_total && (
            <div className="text-[#272523] text-base font-normal font-['Questrial'] leading-snug">
              ${(order?.net_total).toFixed(2)}
            </div>
          )}
        </div>
      </div>
      <div className="md:self-center mx-3 lg:mx-5">
        <div
          onClick={() => dispatch(addToast(`Navigate Order #${order?.id}`))}
          className="cursor-pointer flex py-[16px] px-[10px] items-center gap-[8px] font-['Questrial'] text-[16px] font-normal underline text-[#272523]"
        >
          <div className="text-nowrap">View Details</div>
          <IconRight />
        </div>
      </div>
    </div>
  );
};

const CustomerOrderOverview = () => {
  const [allOrders, setAllOrders] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      try {
        const response = await getRequest(
          "customer/orders",
          {},
          "shipping_labels"
        );
        if (response && response.length > 0) setAllOrders(response);
      } catch (error) {
        dispatch(addToast(error))
      }
    })()
  }, [])

  return (
    <div className="m-5 flex h-[calc(100vh-120px)] overflow-auto">
      <div className="w-full">
        <OrderHistory
          userData={{ orders: allOrders }}
          OrderDisplay={CustomOrderDisplay}
        />
      </div>
    </div>
  );
};

export default CustomerOrderOverview;
