import React, { useState } from "react";
import Button from "@mui/material/Button";
import { useDropzone } from "react-dropzone";
import { downloadCSV, extractOriginalCSVData } from "../../utils/customerList";
import { Box, Typography } from "@mui/material";
import { DownloadIcon, UploadIcon } from "../../common/icons";
import CustomTable from "../Table";
import { UPLOAD_CUSTOMER_CSV_CONTENT } from "../../constants";

const SetupCustomer = ({ file, setFile, handleBack, handleNext, activeStep, setMaxWidth }) => {
  const [originalHeaders, setOriginalHeaders] = useState([]);
  const [originalRows, setOriginalRows] = useState([]);
  const [error, setError] = useState(null);

  const { getRootProps, getInputProps } = useDropzone({
    accept: ".csv",
    onDrop: (acceptedFiles) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const csvData = e.target.result;
        const { headers, data, error } = extractOriginalCSVData(csvData);
        if (error) {
          setError(error);
          setFile(null);
        } else {
          setFile(acceptedFiles[0]);
          setOriginalHeaders(headers);
          setOriginalRows(data);
          setError(null);
          setMaxWidth('md');
        }
      };
      reader.readAsText(acceptedFiles[0]);
    },
  });

  const handleSkip = () => {
    handleNext();
  }

  return (
    <div className="flex flex-col gap-3 m-8">
      {activeStep > 0 && (
        <div className="text-stone-400 text-base font-normal font-['Questrial'] leading-snug">
          Step {activeStep} / 5
        </div>
      )}
      <p className="text-stone-800 text-lg font-semibold font-['Montserrat'] leading-[25.20px]">
        Customer setup
      </p>

      <p className="text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
        Build your customer list from importing a customer list below. You can
        always add new customers inside the product.
      </p>

      <Typography
        variant="body2"
        style={{
          cursor: "pointer",
          textDecoration: "underline",
          display: "flex",
          alignItems: "center",
        }}
        onClick={() => downloadCSV(
          UPLOAD_CUSTOMER_CSV_CONTENT,
          "Customer Upload CSV Template.csv"
        )}
      >
        <DownloadIcon style={{ marginRight: "4px" }} />
        Download CSV Template
      </Typography>

      {!file && (
        <Box
          {...getRootProps({ className: "dropzone" })}
          style={{
            border: "0.5px solid #C9C8C8",
            borderRadius: "8px",
            padding: "16px 0px",
            textAlign: "center",
            cursor: "pointer",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <input {...getInputProps()} />
          <UploadIcon />
          <div className="w-[182px] text-left ml-4">
            <span className="text-green-700 text-base font-normal font-['Questrial'] leading-snug">
              Browse file
            </span>
            <span className="text-stone-400 text-base font-normal font-['Questrial'] leading-snug">
              {" "}
              or drag & drop
              <br />
              to upload
            </span>
          </div>
        </Box>
      )}
      {error && (
        <Box mt={2}>
          <Typography variant="body2" color="error">
            {error}
          </Typography>
        </Box>
      )}

      {file && originalRows?.length > 0 && (
        <React.Fragment>
          <Typography variant="body">File: {file.name}</Typography>
          <Box
            mt={1}
            style={{
              overflowY: "auto",
              maxHeight: "50vh",
              overflowX: "auto",
            }}
          >
            <Box style={{ overflowX: "auto" }}>
              <CustomTable
                columns={originalHeaders.map((header) => ({
                  field: header,
                  label: header,
                  width: "150px",
                }))}
                rows={originalRows.map((row) =>
                  row.reduce((acc, cell, index) => {
                    acc[originalHeaders[index]] = cell;
                    return acc;
                  }, {})
                )}
                sortable={false}
                searchable={false}
                csvPreview={true}
              />
            </Box>
          </Box>
        </React.Fragment>
      )}

      <div className="grid grid-cols-1 sm:grid-cols-3 gap-5 mt-4 ml-auto w-[90%] max-w-[600px]">
        <div className="flex items-center justify-end">
          <div className="underline cursor-pointer" onClick={handleSkip}>
            Skip for now
          </div>
        </div>
        <Button
          variant="outlinedSecondary"
          className="w-full"
          onClick={() => {
            setFile(null);
            handleBack();
          }}
        >
          Back
        </Button>
        <Button
          variant="containedPrimary"
          className="w-full"
          onClick={handleNext}
          disabled={originalRows.length === 0}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default SetupCustomer;
