import React, { useEffect, useRef, useState } from "react";
import isEqual from "lodash/isEqual";
import moment from "moment";
import { Box } from "@mui/material";
import MediaQuery, { useMediaQuery } from "react-responsive";
import { useReactToPrint } from "react-to-print";

import AddItem from "../components/POS/NewOrder/AddItem";
import CalculatePayment from "../components/POS/NewOrder/CalculatePayment";
import CreateCustomer from "../components/POS/NewOrder/CreateCustomer";
import EntityPage from "../components/EntityPage";
import NewDiscount from "../components/POS/NewOrder/NewDiscount";
import CartDetail from "../components/POS/NewOrder/CartDetail";
import PaymentMethod from "../components/POS/NewOrder/PaymentMethod";
import SelectDiscount from "../components/POS/NewOrder/SelectDiscount";
import SelectCustomer from "../components/POS/NewOrder/SelectCustomer";

import { POS_NEW_ORDER_STEPS } from "../constants";
import OrderSuccess from "../components/POS/NewOrder/OrderSuccess";
import { selectCurrentUser } from "../redux/authSlice";
import ManualCardPayment from "../components/POS/NewOrder/ManualCardPayment";
import { useDispatch, useSelector } from "react-redux";
import { addToast } from "../redux/toastSlice";
import ScanCard from "../components/POS/NewOrder/ScanCard";
import { isObjectEmpty } from "../utils";
import { setLoading } from "../redux/loadingSlice";
import AttachCustomer from "../components/POS/NewOrder/AttachCustomer";
import {
  initialCartState,
  selectCurrentCart,
  updateCart,
} from "../redux/cartSlice";
import { getPOS, getStateTaxes } from "../api/pos";
import {
  calculateDiscount,
  calculateProductSubtotal,
  calculateProductsSalesTax,
  calculateServiceSubtotal,
  calculateServicesSalesTax,
} from "../utils";
import CartBadge from "../components/POS/NewOrder/CartBadge";
import {
  PRODUCT_LINE_ITEMS,
  STORE_ORDER_LINE_ITEMS,
  STORES,
} from "../constants/BackendRoutes";
import { getRequest } from "../api";
import Small from "../components/Order/PrintLayouts/Small";
import Medium from "../components/Order/PrintLayouts/Medium";
import LetterSize from "../components/Order/PrintLayouts/Large";

const NewOrder = () => {
  const [replica, setReplica] = useState()
  const [currentStep, setCurrentStep] = useState(0);
  const [cart, setCart] = useState(initialCartState);
  const [currentOrder, setCurrentOrder] = useState({});
  const [products, setProducts] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [previouslyDesktopScreen, setPreviouslyDesktopScreen] = useState(false);

  const [editMode, setEditMode] = useState(false);
  const [duplicateMode, setDuplicateMode] = useState(false);

  const prevCartRef = useRef(cart);
  const printRef = useRef();
  const isDesktopScreen = useMediaQuery({ query: "(min-width: 1024px)" });

  const dispatch = useDispatch();
  const userData = useSelector(selectCurrentUser);
  const initialCart = useSelector(selectCurrentCart);

  const handlePrint = useReactToPrint({
    pageStyle: `@media print {
      @page {
          size: 10in 15in;
        }
      }`,
    content: () => printRef.current,
  });

  useEffect(() => {
    setCurrentOrder(cart);
  }, [cart]);

  const addLineItem = (newItem, itemType) => {
    setCart((prev) => ({
      ...prev,
      [`${itemType}LineItems`]: [...prev[`${itemType}LineItems`], newItem],
    }));
  };

  const getActiveComponent = () => {
    switch (currentStep) {
      case POS_NEW_ORDER_STEPS.ADD_ITEM:
        return (
          <AddItem
            cart={cart}
            setCart={setCart}
            replica={replica}
            setReplica={setReplica}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            editMode={editMode}
            setEditMode={setEditMode}
            duplicateMode={duplicateMode}
            setDuplicateMode={setDuplicateMode}
            products={products}
            subCategories={subCategories}
            isDesktopScreen={isDesktopScreen}
            addServiceLineItem={(newItem) => addLineItem(newItem, "service")}
            addProductLineItem={(newItem) => addLineItem(newItem, "product")}
          />
        );
      case POS_NEW_ORDER_STEPS.CREATE_NEW_DISCOUNT:
        return (
          <NewDiscount
            cart={cart}
            setCart={setCart}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
          />
        );
      case POS_NEW_ORDER_STEPS.ADD_DISCOUNT:
        return (
          <SelectDiscount
            cart={cart}
            setCart={setCart}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            isDesktopScreen={isDesktopScreen}
          />
        );
      case POS_NEW_ORDER_STEPS.PAYMENT_METHOD:
        return (
          <PaymentMethod
            cart={cart}
            setCart={setCart}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            isDesktopScreen={isDesktopScreen}
          />
        );
      case POS_NEW_ORDER_STEPS.CALCULATE_PAYMENT:
        return (
          <CalculatePayment
            cart={cart}
            setCart={setCart}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
          />
        );
      case POS_NEW_ORDER_STEPS.CARD_PAYMENT:
        return (
          <ManualCardPayment
            cart={cart}
            setCart={setCart}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
          />
        );
      case POS_NEW_ORDER_STEPS.SCAN_CARD:
        return (
          <ScanCard
            cart={cart}
            setCart={setCart}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
          />
        );
      case POS_NEW_ORDER_STEPS.SELECT_CUSTOMER:
        return (
          <SelectCustomer
            cart={cart}
            setCart={setCart}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            isDesktopScreen={isDesktopScreen}
          />
        );
      case POS_NEW_ORDER_STEPS.CREATE_CUSTOMER:
        return (
          <CreateCustomer
            cart={cart}
            setCart={setCart}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
          />
        );
      case POS_NEW_ORDER_STEPS.ATTACH_CUSTOMER:
        return (
          <AttachCustomer
            cart={cart}
            setCart={setCart}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            isDesktopScreen={isDesktopScreen}
          />
        );
      case POS_NEW_ORDER_STEPS.ORDER_SUCCESS:
        return (
          <OrderSuccess
            cart={cart}
            setCart={setCart}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
          />
        );
      case POS_NEW_ORDER_STEPS.ORDER_CART:
        return (
          <CartDetail
            defaultCart={initialCartState}
            cart={cart}
            setCart={setCart}
            setReplica={setReplica}
            editMode={editMode}
            setEditMode={setEditMode}
            duplicateMode={duplicateMode}
            setDuplicateMode={setDuplicateMode}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
          />
        );
      default:
        return;
    }
  };

  const setupTaxesInfo = async (stores) => {
    if (stores[0].addresses?.length > 0) {
      const shopAddress = stores[0]?.addresses?.find(
        (address) => address.address_type === "shop"
      );
      const taxes = await getStateTaxes(shopAddress?.state, dispatch);
      setCart((prev) => ({
        ...prev,
        productSalesTaxRate: taxes.product_sales_tax,
        serviceSalesTaxRate: taxes.service_sales_tax,
      }));
    } else {
      dispatch(addToast("Please provide Shop address to calculate sales tax."));
    }
  };

  useEffect(() => {
    if (cart) {
      dispatch(updateCart(cart));
      const prevCart = prevCartRef.current;
      const { serviceLineItems, productLineItems, discount, rushOrder } = cart;
      const updatedCart = { ...cart };

      let hasChanges = false;
      const isDiscountChanged = !isEqual(prevCart?.discount, discount);
      const isServiceLineItemsChanged = !isEqual(
        prevCart?.serviceLineItems,
        serviceLineItems
      );
      const isProductLineItemsChanged = !isEqual(
        prevCart?.productLineItems,
        productLineItems
      );
      const isRushOrderChanged = !isEqual(prevCart?.rushOrder, rushOrder);

      if (
        isServiceLineItemsChanged ||
        isRushOrderChanged ||
        isProductLineItemsChanged ||
        isDiscountChanged
      ) {
        const serviceTotal = calculateServiceSubtotal(
          serviceLineItems,
          rushOrder
        );
        const productTotal = calculateProductSubtotal(productLineItems);

        updatedCart.serviceSubTotal = serviceTotal;
        updatedCart.productSubTotal = productTotal;
        updatedCart.totalDiscount = calculateDiscount(
          serviceTotal,
          productTotal,
          discount
        );
        updatedCart.serviceSalesTax = calculateServicesSalesTax(
          serviceTotal,
          productTotal,
          discount,
          cart.serviceSalesTaxRate
        );
        updatedCart.productSalesTax = calculateProductsSalesTax(
          serviceTotal,
          productTotal,
          discount,
          cart.productSalesTaxRate
        );

        hasChanges = true;
      }

      if (hasChanges) {
        setCart(updatedCart);
        prevCartRef.current = cart;
        return;
      }
    }
  }, [cart]);

  useEffect(() => {
    const setupPOS = async () => {
      if (userData) {
        const { stores } = userData;

        await getPOS(setProducts, setSubCategories, stores, dispatch);
        await setupTaxesInfo(stores);

        setCart((prev) => ({
          ...prev,
          rushOrder: { ...prev.rushOrder, price: stores[0]?.rush_fee },
        }));
      }
    };

    setupPOS();
  }, [userData]);

  useEffect(() => {
    if (isDesktopScreen && !previouslyDesktopScreen) {
      if (currentStep === POS_NEW_ORDER_STEPS.ORDER_CART)
        setCurrentStep(POS_NEW_ORDER_STEPS.ADD_ITEM);
    }

    setPreviouslyDesktopScreen(isDesktopScreen);
  }, [isDesktopScreen]);

  useEffect(() => {
    setCart((prev) => ({ ...initialCart, orderId: null }));
    setCurrentStep(POS_NEW_ORDER_STEPS.ADD_ITEM);
  }, []);

  useEffect(() => {
    if (currentOrder?.orderId) {
      getRequest(
        `${STORES}/${userData.stores[0].id}/orders/${currentOrder?.orderId}`,
        {},
        "user,order_line_items"
      ).then((order) => {
        setCurrentOrder(() => ({
          ...currentOrder,
          data: order,
        }));
      });
    }
  }, [currentOrder?.orderId]);

  useEffect(() => {
    const printLabels = async () => {
      if (currentOrder?.data) {
        await handlePrint();

        await new Promise((resolve) => setTimeout(resolve, 1000));
        setCurrentOrder({});
      }
    };

    if (!isObjectEmpty(currentOrder)) {
      printLabels();
    }
  }, [currentOrder, handlePrint]);

  return (
    <EntityPage
      title="New Order"
      className="bg-[#F8F8F8]"
      classNameLayout="hidden md:flex p-0 md:p-5"
    >
      <Box className="flex gap-8 w-full h-[calc(100vh-67px-40px)] md:h-[calc(100vh-80px-40px)]">
        <MediaQuery minWidth={1024}>
          <CartDetail
            defaultCart={initialCartState}
            cart={cart}
            setCart={setCart}
            setReplica={setReplica}
            editMode={editMode}
            setEditMode={setEditMode}
            duplicateMode={duplicateMode}
            setDuplicateMode={setDuplicateMode}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
          />
        </MediaQuery>
        {getActiveComponent()}
      </Box>
      <div style={{ display: "none" }}>
        <div ref={printRef}>
          {/* {currentOrder &&
            currentOrder.data &&
            currentOrder.data.order_line_items.map((_, index) => (
              <div key={index} style={{ pageBreakAfter: "always" }}>
                <Small currentItem={index + 1} data={currentOrder?.data} />
              </div>
            ))} */}
          {/* {currentOrder &&
            currentOrder.data &&
            currentOrder.data.order_line_items.map((_, index) => (
              <div key={index} style={{ pageBreakAfter: "always" }}>
                <Medium currentItem={index + 1} data={currentOrder?.data} />
              </div>
            ))} */}
          {currentOrder &&
            currentOrder.data &&
            currentOrder.data.order_line_items.map((_, index) => (
              <div key={index} style={{ pageBreakAfter: "always" }}>
                <LetterSize currentItem={index + 1} data={currentOrder?.data} />
              </div>
            ))}
        </div>
      </div>
    </EntityPage>
  );
};

export default NewOrder;
