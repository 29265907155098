import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { jwtDecode } from "jwt-decode"; // Ensure this import is correct
import {
  retrieveTokenFromLocalStorage,
  retrieveUserIdFromLocalStorage,
  destroyDataFromLocalStorage,
} from "../utils/localStorage";
import { fetchUserData } from "../redux/authActions";
import { selectCurrentUser, setCurrentUser } from "../redux/authSlice";
import {
  CUSTOMER_ROUTES,
  DASHBOARD,
  LOGIN,
  ONBOARDING,
} from "../constants/FrontendRoutes";
import { addToast } from "../redux/toastSlice";
import { LOGIN_TYPE } from "../constants";

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const jwtToken = retrieveTokenFromLocalStorage();
  const userId = retrieveUserIdFromLocalStorage();
  const isLocalStorageAvailable = jwtToken && userId;

  const userData = useSelector(selectCurrentUser);
  const location = useLocation();
  const dispatch = useDispatch();

  let decodedToken;

  if (jwtToken) {
    try {
      decodedToken = jwtDecode(jwtToken);
    } catch (error) {
      dispatch(addToast(error));
    }
  }

  useEffect(() => {
    if (isLocalStorageAvailable && !userData) {
      dispatch(fetchUserData({ id: userId, login_type: decodedToken?.login_type }));
    }
  }, [dispatch, isLocalStorageAvailable]);

  if (!isLocalStorageAvailable) {
    dispatch(setCurrentUser(null));
    return <Navigate to={LOGIN} />;
  }

  if (decodedToken?.login_type === LOGIN_TYPE.CUSTOMER) {
    const availableCustomerRoutes = Object.values(CUSTOMER_ROUTES);
    if (!availableCustomerRoutes.includes(location.pathname)) {
      return <Navigate to={CUSTOMER_ROUTES.ORDERS_OVERVIEW} />;
    }
    return <Component {...rest} />;
  }

  if (decodedToken?.login_type === LOGIN_TYPE.STAFF) {
    const availableCustomerRoutes = Object.values(CUSTOMER_ROUTES);
    if (availableCustomerRoutes.includes(location.pathname)) {
      return <Navigate to={DASHBOARD} />;
    }

    if (userData) {
      const { status } = userData;
      if (status === "onboarding" && location.pathname !== ONBOARDING) {
        return <Navigate to={ONBOARDING} />;
      }
      if (status !== "onboarding" && location.pathname === ONBOARDING) {
        return <Navigate to={DASHBOARD} />;
      }
      return <Component {...rest} />;
    }
  }

  return null;
};

export default ProtectedRoute;
